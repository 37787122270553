import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Today is our Made it to Madison in house competition starting at
8:30am! If you aren’t competing come out and cheer on your fellow Ville
members. All classes are cancelled today.`}</em></strong></p>
    <p><strong parentName="p">{`WOD 1`}</strong></p>
    <p>{`21-Jerks (135/95)`}</p>
    <p>{`9-Bar Muscle Ups`}</p>
    <p>{`15-Jerks`}</p>
    <p>{`7-Bar Muscle Ups`}</p>
    <p>{`9-Jerks`}</p>
    <p>{`5-Bar Muscle Ups`}</p>
    <p>{`for time. (10:00 cap)`}</p>
    <p><strong parentName="p">{`WOD 2:`}</strong></p>
    <p>{`60/50-Calorie Ski Erg`}</p>
    <p>{`50-Air Squats`}</p>
    <p>{`40-Power Cleans (95/65)`}</p>
    <p>{`30-Front Squats (95/65)`}</p>
    <p>{`20-Ring Dips`}</p>
    <p>{`10-Wall Walks`}</p>
    <p>{`for time. (15:00 cap)`}</p>
    <p><strong parentName="p">{`WOD 3:`}</strong></p>
    <p>{`3:00 As Many Reps As Possible:`}</p>
    <p>{`Power Snatch (115/75)`}</p>
    <p>{`rest 2:00, then`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`15-T2B`}</p>
    <p>{`10-Burpee Box Jump Overs (24/20, box facing)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      